<template>
  <v-app class="login">

      <!-- =======================================================
                                Overlay
      ============================================================-->
      <v-overlay :value="overlay">
        <v-progress-circular :size="70" :width="7" color="blue" indeterminate></v-progress-circular>
      </v-overlay>

    <!-- =======================================================
                                Snackbar
    ============================================================-->
    <v-snackbar v-model="snackbar" >
      {{ message }}
      <v-spacer />
      <template v-slot:action="{ attrs }">
        <v-btn color="blue" text v-bind="attrs" @click="snackbar = false">Cancelar</v-btn>
      </template>
    </v-snackbar>

    <v-spacer />

    <v-container cols="12" class="d-flex py-0 pa-0 justify-center align-center">
      <v-col  sm="12" md="8" lg="6" xl="5" v-if="!forgotPassword && !alterPassword">
          <v-btn text class="my-5 pa-0 caption white--text" to="/">
            <v-icon>mdi-chevron-left</v-icon>
            Voltar ao site
          </v-btn>
        <v-card class="card pa-5">
          <v-img class="white--text align-end mb-5" height="100px" contain :src="loginImg" />
          <h2 class="text-center mb-5 blue--text">Login</h2>
          <v-text-field v-model="user.email" label="E-mail" required outlined></v-text-field>
          <v-text-field v-model="user.password" type="password" label="Senha" outlined required></v-text-field>
          <v-container class="d-flex py-0 flex-column justify-space-between align-center">
            <v-btn class="mb-5" color="success" @click="login">Entrar</v-btn>
            <v-btn text class="mt-5 caption blue--text" @click="forgotPassword=true">Esqueci a senha</v-btn>

          </v-container>
        </v-card>
            
      </v-col>
      <v-col  sm="12" md="8" lg="6" xl="5" v-if="forgotPassword && !alterPassword">
        <v-card class="card pa-5">
          <v-img class="white--text align-end mb-5" height="100px" contain :src="forgotPasswordImg" />
          <h2 class="text-center mb-5 blue--text">Esqueci minha senha</h2>
          <v-text-field v-model="user.email" label="E-mail" required outlined></v-text-field>
          <!-- <v-text-field v-model="user.password" type="password" label="Senha" outlined required></v-text-field> -->
          <v-container class="d-flex py-0 flex-column justify-space-between align-center">
            <v-btn class="mb-5" color="success" @click="forgotPasswordSend">Enviar</v-btn>
            <v-btn text class="mt-5 caption blue--text"  @click="forgotPassword=!forgotPassword">Voltar</v-btn>
          </v-container>
        </v-card>
      </v-col>
      <v-col  sm="12" md="8" lg="6" xl="5" v-if="alterPassword && !forgotPassword">
        <v-card class="card pa-5">
          <v-img class="white--text align-end mb-5" height="100px" contain :src="forgotPasswordImg" />
          <h2 class="text-center mb-5 blue--text">Alterar senha</h2>
           <v-text-field v-model="password" type="password" label="Nova senha" outlined required></v-text-field>
          <!-- <v-text-field v-model="user.password" type="password" label="Senha" outlined required></v-text-field> -->
          <v-container class="d-flex py-0 flex-column justify-space-between align-center">
            <v-btn class="mb-5" color="success" @click="resetPasswordSend">Confirmar</v-btn>
            <v-btn text class="mt-5 caption blue--text"  @click="alterPassword = false">Sair</v-btn>
          </v-container>
        </v-card>
      </v-col>
    </v-container>
    <v-spacer />
  </v-app>
</template>

<script>
import userService from "../../service/userService";

export default {
  name: "Login",
  data() {
    return {
      forgotPassword:false,
      alterPassword:false,
      snackbar:false,
      overlay:false,
      password:'',
      message:'',
      user: {
        email: "",
        password: ""
      },
      loginImg: require("../../assets/login.png"),
      forgotPasswordImg:require("../../assets/password.svg")
    };
  },
  methods: {
    login() {
      userService.login(this.user).then(res => {

        if(res.data.message){
          this.message = res.data.message
          this.snackbar = true
        }else{
          this.$router.push({ path: `/cms` })
        }
        
      });
    },
    forgotPasswordSend(){
      this.overlay=true
      userService.recoverPassword({email:this.user.email}).then(res => {

        if(res.data.message){
          this.message = res.data.message
          this.snackbar = true
          this.forgotPassword = false
          this.overlay=false
        }
        
      }).catch(error => {
        this.overlay=false
      });
    },
    resetPasswordSend(){

      if(this.token){
        
         userService.resetPassword({token:this.token, password:this.password}).then(res => {

        if(res.data.message){
          this.message = res.data.message
          this.snackbar = true
          this.alterPassword = false
        }
      });
      }
  }
  },
  mounted(){
     this.token = this.$route.params.token
    if(this.token){
      this.alterPassword = true
    }
    
  }
};
</script>

<style lang="sass" scoped>
.login
  background: #2b3a5d
  background: linear-gradient(0deg,#2d5ead,#143263)

#app
  background: #2b3a5d
  background: linear-gradient(0deg,#2d5ead,#143263)
</style>
