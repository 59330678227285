import axios from 'axios'

const token = localStorage.getItem('user-token')
const headers = {headers: {'Authorization': `${token}`}}
const BASE_API = process.env.VUE_APP_BASE_API
const name = 'user'

export default {

  create(data) {
    return axios.post(`${BASE_API}/${name}/create`, data,headers)
  },
  getAllProfile() {
    return axios.get(`${BASE_API}/${name}/profile`)
  },
  login(data) {
    return axios.post(`${BASE_API}/${name}/login`, data).then(res => {
      const token = res.data.token
      localStorage.setItem('user-token',
        token) 
      return res
    }).catch(err => {
      localStorage.removeItem(
        'user-token') 

    })
  },
  recoverPassword(data){
    return axios.post(`${BASE_API}/${name}/recoverPassword`, data).then(res => {
      this.logout() 
      return res
    }).catch(err => {
      localStorage.removeItem(
        'user-token') 

    })
  },
  resetPassword(data){
    return axios.post(`${BASE_API}/${name}/resetPassword`, data).then(res => {
      return res
    }).catch(err => {
      localStorage.removeItem(
        'user-token') 
    })
  },
  logout() {
    return localStorage.removeItem('user-token')
  },
  get(data) {
    return axios.post(`${BASE_API}/${name}/`, data,headers)
  },
  update(id, data) {
    return axios.post(`${BASE_API}/${name}/update/${id}`, data, headers)
  },
  delete(id) {
    return axios.post(`${BASE_API}/${name}/destroy/${id}`,{},headers)
  }

}
